import PropTypes from "prop-types"
import React from "react"
import suso from "../images/logo-susodigital-com.png"
import artefact from "../images/artefact.png"
import wave from "../images/wave.png"
import gsp from "../images/login_logo.png"

const BoxContent = ({ BoxContent }) => {
  console.log('BoxContent:', BoxContent)
  return(
    <div className="box-content">
    {BoxContent && BoxContent.map(box => {
      return (
        <section className="box-content__section">
          <div className={box.class}>
            <div className="box-content__section-content-text" >
              <div  className="box-content__section-content-text-cover" ></div>
              <h2 className="box-content__section-content-text-title">
                {box.title}
              </h2>
              {/* {box.paragraphs && box.paragraphs.map(content => { */}
                {/* return( */}
                  <p className="box-content__section-content-text-paragraph">{box.paragraph}</p>
                {/* ) */}
              {/* })} */}
            </div>
            <div className="box-content__section-content-interactive">
              <img src={box.logo} alt=""/>
            </div>
          </div>
        </section>
      )
    })}
</div>

    )
}


BoxContent.propTypes = {
  siteTitle: PropTypes.string,
}

BoxContent.defaultProps = {
    BoxContent: [
    {
        id: 1,
        class:'box-content__section-content',
        logo: gsp,
        title: 'SEO Manager',
        paragraph: 'Contractor, responsible for this trimester’s growth planning for the company’s main market, US. Developing a concept for better internal linking. Developing a content strategy for Black Friday. Developing an integrated concept to strengthen and improve EAT signals. Technical onsite auditing and creating a roadmap for implementation e.g. improving page experience signals, implementing schema.org, consolidating sitemap files etc. Developing SEO-friendly guidelines for Operations teams globally to use in their day-to-day.'
    },
    {
        id: 2,
        class:'box-content__section-content reverse',
        logo: artefact,
        title: 'SEO Manager',
        paragraph: 'SEO account manager for some notable ecommerce brands i.e. Paperchase, Fred Perry and Crucial. Developing a better understanding of specific CMS capabilities: WebSphere, Magento and AEM. Managing start-to-finish SEO projects such as website migrations and taxonomy reviews.        Completing keyword research and competitor analysis with the goal of identifying content gaps and creating SEO content briefs for copywriters.Daily working with several SEO tools e.g Screaming Frog, Sitebulb, Google Tools, Sistrix, GetSTAT.'
    },
    {
        id: 3,
        class:'box-content__section-content ',
        title: 'Organic Performance Executive',
        logo: wave,
        paragraph: 'Developing the organic digital presence of some of the world’s largest brands such as General Electric, Pfizer and Colgate by planning and implementing content and technical changes in Drupal, performing on-site and off-site audits, optimizing their YouTube channel, Wikipedia page and more. Highly involved in several website migrations and releases. Efficiently working with a wide range of SEO tools e.g. Deepcrawl, SearchMetrics, BrightEdge, AWR. Attending conferences such as Google mSEO for Agencies and Brighton SEO.'
    },
    {
        id: 4,
        class:'box-content__section-content reverse',
        title: 'SEO Analyst',
        logo: suso,
        paragraph: 'Operations Manager of 10 off-shore teams managing the link building strategy for 100 clients. Campaign Manager of 18 clients (eg. Angama Mara Safari, TravelFreak), responsible for on-site and off-site SEO strategy & implementation (Wordpress, Shopify), client communication and reporting. Conducting keyword research, technical on-site audits, competitor research, content optimization, conversion optimization and site speed audits, link building and outreach, backlink audits. Efficiently working with a wide range of SEO tools e.g. Ahrefs, SEMRush, Moz, Majestic.'
    },
]

}

export default BoxContent

import React from "react"
import CV from "../files/CV.pdf"
import BoxContent from "../components/BoxContent"


import Layout from "../components/layout"
import SEO from "../components/seo"

const Experience = () => (
  <Layout>
    <div className="content-wrapper">
      <SEO title="Experience" description="Here you can see my experience and my Experience"/>
      <h1 className="white"
       style={{
        maxWidth: `1200px`,
        margin: `auto`,
        marginBottom:`16px`
      }}
      >Experience</h1>
      <BoxContent/>
    <a style={{color: 'white'}} target="blank" href={CV}>Download my CV here.</a>
    </div>
  </Layout>
)

export default Experience
